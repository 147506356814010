<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <p><strong style="color: white">porwod</strong> © 2021</p>
      <p>
        <a href="https://discord.gg/fmKEdDE2jX" target="_blank"
          ><b-icon pack="fab" icon="discord" size="is-medium"> </b-icon
        ></a>
        <a
          href="https://www.instagram.com/porwod/"
          class="space"
          target="_blank"
          ><b-icon pack="fab" icon="instagram" size="is-medium"> </b-icon
        ></a>
        <a href="https://www.facebook.com/porwod/" class="space" target="_blank"
          ><b-icon pack="fab" icon="facebook" size="is-medium"> </b-icon
        ></a>
        <a
          href="https://www.linkedin.com/company/porwod/"
          class="space"
          target="_blank"
          ><b-icon pack="fab" icon="linkedin" size="is-medium"> </b-icon
        ></a>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";

footer {
  font-family: $head-font;
  font-size: 1.75rem;
  color: white;
  font-weight: 400;
  padding-bottom: 1rem !important;
  background-color: black;
}

img {
  width: 40px;
  height: auto;
}

i {
  color: #ffffff;
}

a {
  color: #ffffff;
  &:hover {
    color: #ffffff;
  }
  &.space {
    margin-left: 1rem;
  }
}
</style>
