import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Buefy from "buefy";
import "buefy/dist/buefy.css";
import titleMixin from "./mixins/titleMixin";
import VueYandexMetrika from "vue-yandex-metrika";

Vue.mixin(titleMixin);
Vue.use(Buefy);
Vue.config.productionTip = false;

Vue.use(VueYandexMetrika, {
  id: 78961717,
  router: router,
  env: process.env.NODE_ENV,
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
