<template>
  <b-navbar
    :spaced="true"
    wrapper-class="container"
    type="is-black"
    :fixed-top="true"
  >
    <template #brand>
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img
          class="logo"
          src="../assets/images/porwod_logo_white.png"
          alt="Lightweight UI components for Vue.js based on Bulma"
        />
      </b-navbar-item>
    </template>
    <template #start>
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        Home
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/faq' }">
        FAQ
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/creator' }">
        Creator
      </b-navbar-item>
      <!-- Disable contact page until I write the API -->
      <!-- <b-navbar-item tag="router-link" :to="{ path: '/contact' }">
        Contact Us
      </b-navbar-item> -->
    </template>
    <!-- <template #end>
      <b-navbar-item href="https://igg.me/at/porwod" target="_blank">
        All about laptop.
      </b-navbar-item>
      <b-navbar-item href="https://igg.me/at/porwod" target="_blank">
        <img
          src="../assets/images/IGG_FundedWithBadges_Gogenta_RGB_Rectangle.png"
          alt="indegogo"
        />
      </b-navbar-item>
    </template> -->
  </b-navbar>
</template>

<script>
export default {
  name: "Navbar",
};
</script>

<style lang="scss" scoped>
.navbar-item img {
  max-height: 3rem;
}
</style>
