<template>
  <div
    class="container"
    style="padding-left: 2rem; padding-right: 2rem; margin-block-end: 6rem"
  >
    <!-- Hero Landing -->
    <section class="section">
      <div class="container">
        <div class="columns is-centered">
          <figure>
            <div class="hero-body">
              <p class="title" style="">Case, Computer, Height Adjuster</p>
            </div>
          </figure>
        </div>
      </div>
    </section>

    <div class="container" style="max-width: 1024px">
      <figure class="image is-16by9">
        <iframe
          class="has-ratio"
          width="1024"
          height="576"
          src="https://www.youtube.com/embed/SVO9ptHyF84"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </figure>
    </div>

    <!-- Section 1 -->
    <div class="container" id="sections">
      <div class="columns is-centered">
        <div class="columns">
          <div class="column">
            <h1 class="head">Porwod is easy to carry!</h1>
            <p class="description">
              Porwod is an easy-to-carry product that allows using a laptop with
              one or more screens by adjusting the required ergonomic height.
            </p>
          </div>
          <div class="column box">
            <img
              src="../assets/content/section-1.gif"
              data-src="../assets/content/section-1.gif"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Section 2 -->
    <div class="container" id="sections">
      <div class="columns is-centered">
        <div class="columns">
          <div class="column box">
            <img src="../assets/content/section-2.jpg" />
          </div>
          <div class="column">
            <h1 class="head">
              Porwod is well designed by experienced engineer
            </h1>
            <p class="description">
              The concept phase has been completed after 3 years of work. The
              first goal is to create a prototype. To do this, a team,
              customized hardware, and development/manufacturing budget are
              needed. The fund to be raised will be used for these expenses.
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Section 3 -->
    <div class="container" id="sections">
      <div class="columns is-centered">
        <div class="columns">
          <div class="column">
            <h1 class="head">Made for everyone</h1>
            <p class="description">
              This product meets the people's need who desire more than one
              screen at the same time, such as design engineers, software
              developers, stock traders, graphic designers, sales
              representatives, teachers, directors, e-sports players, project
              managers, or whoever works remotely.
            </p>
            <p class="description">
              The invention meets the multiple screen need of the users with the
              number of screens from 1 to 4 depending on the desired
              combination. In addition, other computer users can connect and use
              the displays via connection interfaces (such as HDMI, USB, or
              VGA).
            </p>
          </div>
          <div class="column box">
            <img src="../assets/content/section-3.jpg" />
          </div>
        </div>
      </div>
    </div>

    <!-- Section 4 -->
    <div class="container" id="sections">
      <div class="columns is-centered">
        <div class="columns">
          <div class="column box">
            <img src="../assets/content/section-4.jpg" />
          </div>
          <div class="column">
            <h1 class="head">We thought everything you needed</h1>
            <p class="description">
              Current products in the market meet only a part of the user needs.
              For example;
            </p>
            <ul
              class="description"
              style="list-style-type: circle; padding-left: 3rem"
            >
              <li>External screens</li>
              <li>Laptops having multi screens</li>
              <li>Height adjuster</li>
              <li>Waterproofing</li>
            </ul>
            <p class="description">
              The common problem in all of the product groups listed above is
              that they do not have a solution for carrying the laptop charger
              and cables. Functionality and elegance do not come together
              easily. Every detail in the Porwod has been carefully designed to
              respond to users requests.
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Specs -->
    <div class="container">
      <div class="columns is-centered">
        <h1 class="head">Specifications & Some details</h1>
      </div>
    </div>
    <div class="container" style="margin-bottom: 3rem">
      <div class="columns is-centered">
        <p class="description">
          Porwod has a pending patent application. Because the height of the
          screens will be adjustable, it will be in the most ergonomic position
          for users. The screen height can go up to ~250 mm (~9.84 in) from the
          desk surface to the bottom point of displays.
        </p>
        <ul
          class="description"
          style="list-style-type: circle; padding-left: 2rem"
        >
          <li>300x420x60 mm / 11.81x16.53x2.36 inch</li>
          <li>~5.5 kg / ~12 lb</li>
          <li>Waterproof</li>
          <li>
            <strong>Note:</strong> The weight value is an estimated
            approximation for the model with 15 "size and 4 screens based on
            current computer and display weights.
          </li>
        </ul>
      </div>
    </div>

    <!-- Carousel-->
    <div class="container box">
      <template>
        <b-carousel>
          <b-carousel-item>
            <figure class="image is-16b9">
              <img src="../assets/content/Artboard-1.png" />
            </figure>
          </b-carousel-item>
          <b-carousel-item>
            <figure class="image is-16by9">
              <img src="../assets/content/Artboard-2.png" />
            </figure>
          </b-carousel-item>
          <b-carousel-item>
            <figure class="image is-16by9">
              <img src="../assets/content/Artboard-3.png" />
            </figure>
          </b-carousel-item>
          <b-carousel-item>
            <figure class="image is-16by9">
              <img src="../assets/content/Artboard-4.png" />
            </figure>
          </b-carousel-item>
          <b-carousel-item>
            <figure class="image is-16by9">
              <img src="../assets/content/Artboard-5.png" />
            </figure>
          </b-carousel-item>
        </b-carousel>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  title: "porwod - Case, Computer, Height Adjuster",
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";

.hero-body {
  text-align: center;
  font-family: $head-font;
  font-weight: 400;
  font-style: normal;
}

.description {
  font-family: $desc-font;
  font-weight: 400;
  font-style: normal;
}

.head {
  font-family: $head-font;
  font-size: 2rem;
  font-weight: 600;
  font-style: normal;
}

#sections {
  margin-block-start: 3.75rem;
  margin-block-end: 3.75rem;
}

p {
  padding: 1rem;
}

h1 {
  padding: 1rem;
}
</style>
